import React, { useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { paymentTransaction } from 'app/apis/point';
import { PATH } from 'app/constants/path';
import { Container } from '../styles/styles';

const PaymentProcessing = () => {
  const navigate = useNavigate();

  const [params]: any = useSearchParams();

  const handlePaymentTransaction = async () => {
    try {
      const res: any = await paymentTransaction({
        outTradeNo: params.get('outTradeNo'),
        result: params.get('result'),
      });

      if (res?.statusCode === 201) {
        navigate(PATH.PAYMENT_SUCCESSFUL);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.statusCode === 400 &&
        error?.response?.data?.code === 29
      ) {
      } else {
        navigate(PATH.PAYMENT_FAILED);
      }
    }
  };

  useEffect(() => {
    if (params.get('outTradeNo') && params.get('result')) {
      handlePaymentTransaction();
    } else {
      navigate(PATH.PROFILE);
    }
  }, [params]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 56,
        color: '#FF347D',
      }}
      spin
    />
  );

  return (
    <Container>
      <h2
        style={{
          color: '#FFF',
          fontSize: '28px',
          fontWeight: '700',
          marginBottom: '40px',
          textAlign: 'center',
        }}
      >
        Payment is being processed, <br />
        please do not exit or reload the page
      </h2>

      <Spin indicator={antIcon} />
    </Container>
  );
};

export default PaymentProcessing;
