import React from 'react';
import { Link } from 'react-router-dom';
import { PATH } from 'app/constants/path';
import styled from 'styled-components/macro';
import { DATA_PARTNER_SITE } from 'utils/commonData';

export const PartnerSite = () => {
  return (
    <Wrapper>
      <DistributionNotice>
        <div className="distribution-content">
          <h3 className="distribution-header">配信店舗＆ライバー大募集！</h3>
          <div className="distribution-desc">
            <p>
              FU-CHA（フーチャ）では、風俗店やメンズエステ店などのライブ配信店舗
              やライバーさんを随時募集しています。参加費は一切不要！
            </p>
            <p>
              詳細は
              <span className="distribution-note">募集要項ページ</span>
              よりご確認お願いいたします。
            </p>
          </div>
        </div>
        <p className="distribution-text">
          <Link className="distribution-text_link" to={PATH.LEGAL}>
            利用規約
          </Link>
          <span>│</span>
          <Link className="distribution-text_link" to={PATH.LEGALNOTICEL}>
            特定商取引法に関する表示
          </Link>
          <span>│</span>
          <Link className="distribution-text_link" to={PATH.PRIVACY}>
            プライバシーポリシー
          </Link>
        </p>
      </DistributionNotice>
      <PartnerSiteBox>
        <h4 className="partner-title">協力サイト</h4>
        <div className="partner-image">
          <a
            href="https://www.fucolle.com/"
            target="_blank"
            rel="noreferrer"
            title="みんなのクチコミで作る「フーコレ」"
          >
            <img
              src="https://www.fucolle.com/link/img/46860-2.gif"
              alt="みんなのクチコミで作る「フーコレ」"
            />
          </a>
        </div>
        <PartnerGroup>
          {DATA_PARTNER_SITE?.map((item, idx) => (
            <a
              key={idx}
              href={item?.url}
              className="partner-item"
              target="_blank"
              rel="noreferrer"
              style={{ backgroundColor: item?.bgColor }}
              title={item?.alt}
            >
              {item?.text}
            </a>
          ))}
        </PartnerGroup>
        <PartnerBannerAds>
          <div className="partner-item-banner">
            <a
              href="https://me.fucolle.com/"
              target="_blank"
              rel="noreferrer"
              title="メンズエステの人気ランキング「メンエスMAP」"
            >
              <img
                src="https://me.fucolle.com/link/img/46860.gif"
                alt="メンズエステの人気ランキング「メンエスMAP」"
              />
            </a>
          </div>
          <div className="partner-item-banner">
            <a
              href="https://hp.fucolle.com/"
              target="_blank"
              rel="noreferrer"
              title="デリヘル無料ホームページ制作"
            >
              <img
                src="https://hp.fucolle.com/img/link/46860.gif"
                alt="デリヘル無料ホームページ制作"
              />
            </a>
          </div>
          <div className="partner-item-banner">
            <a
              href="https://web.fucolle.com/"
              target="_blank"
              rel="noreferrer"
              title="風俗店のホームページ制作屋さん"
            >
              <img
                src="https://web.fucolle.com/img/banner/46860.gif"
                alt="風俗店のホームページ制作屋さん"
              />
            </a>
          </div>
          <div className="partner-item-banner">
            <a
              href="https://aroma.fucolle.com/"
              target="_blank"
              rel="noreferrer"
              title="アロマエステ店の無料ホームページ制作"
            >
              <img
                src="https://aroma.fucolle.com/img/link/46860.gif"
                alt="アロマエステ店の無料ホームページ制作"
              />
            </a>
          </div>
        </PartnerBannerAds>
      </PartnerSiteBox>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const DistributionNotice = styled.div`
  color: #fff;
  margin-top: 50px;

  .distribution-content {
    border: 1px solid #dd8bff;
    margin-bottom: 25px;
  }
  .distribution-header {
    margin: 4px;
    text-align: center;
    font-size: 18px;
    color: #000;
    background-color: #dd8bff;
  }
  .distribution-desc {
    padding: 12px;
    font-size: 12px;
  }

  .distribution-note {
    color: #fffe55;
  }

  .distribution-text {
    text-align: center;
    font-size: 11px;
    span {
      padding: 0 1px;
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    &_link {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media only screen and (max-width: 703px) {
    .distribution-text {
      font-size: 11px;
    }
  }

  @media only screen and (max-width: 380px) {
    .distribution-text {
      font-size: 9px;
    }
  }
`;

const PartnerSiteBox = styled.div`
  margin: 100px 0 30px 0;

  .partner-title {
    color: #fff;
    display: flex;
    flex-direction: row;
    font-size: 14px;

    &:before,
    &:after {
      content: '';
      flex: 1 1;
      border-bottom: 1px solid #fff;
    }

    &:before {
      margin: auto 8px auto 0;
    }

    &:after {
      margin: auto 0 auto 8px;
    }
  }

  .partner-image {
    margin: 20px 0 10px 0;
    img {
      width: 100%;
    }
  }
`;

const PartnerGroup = styled.div`
  display: grid;
  row-gap: 2px;
  column-gap: 2px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  .partner-item {
    min-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    text-decoration: none;
    background-color: #2279d4;
    border-radius: 1px;
  }
`;

const PartnerBannerAds = styled.div`
  margin: 20px 0 60px 0;

  .partner-item-banner {
    padding-top: 10px;

    img {
      width: 100%;
    }
  }
`;
