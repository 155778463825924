import React from 'react';
import { LogoTerms } from 'styles/image';

import { Link } from 'react-router-dom';
import { PATH } from 'app/constants/path';
import { WrapperTermsStyled } from '../CommonCss/styles';
import styled from 'styled-components';

export const Legal = () => {
  return (
    <WrapperTermsStyled>
      <ContainerLegalStyled>
        <header>
          <Link to={PATH.HOME} id="h_logo" title="FU-CHA（フーチャ）">
            <img src={LogoTerms} alt="FU-CHA（フーチャ）" />
          </Link>
        </header>
        <main>
          <div id="cnt">
            <div id="legal">
              <h2 className="s_title">
                <em>利用規約</em>
                <span>LEGAL</span>
              </h2>
              <p className="toptxt">
                FU-CHA（フーチャ）（以下「FC」という）は、登録メンバーの皆様が、スマートフォンやコンピューター・タブレットなど通信機器をインターネットに接続して、オンラインでエンターテインメントコンテンツ
                (「FCコンテンツ」)
                にアクセスできる、パーソナライズされた登録制のサービスを提供します。
                <br />
                本利用規約はお客様による当社サービスの利用に適用されます。本利用規約において「FCサービス」「当社サービス」または「サービス」とは、FU-CHA（フーチャ）コンテンツを発見またはこれにアクセスするためにFCが提供するパーソナライズされたサービスを指し、あらゆる機能、オススメおよびレビュー、当社ウェブサイト、ユーザーインターフェース、ならびに当社サービスに関連する一切のコンテンツおよびソフトウェアが含まれます。本利用規約において「お客様」とは、FU-CHA（フーチャ）新規登録サイトにてユーザーアカウントを作成したかたすべてを指します。
                <br />
              </p>
              <div className="lbox">
                <h3>1.メンバーシップ</h3>
                <ul>
                  <li>
                    <span>(1)</span>
                    お客様のFCメンバーシップは、解約されるまで継続します。FCサービスのご利用にあたっては、インターネットアクセスおよびFC対応デバイスをご用意いただき、無料視聴機関を過ぎると1つ以上のお支払い方法を当社にご提供いただく必要があります。「お支払い方法」とは、現行の、有効かつ当社が承認したお支払い方法を意味し、随時更新することができます。また、お客様の第三者でのアカウントを通した支払いも対象となる場合があります。請求日前にメンバーシップを解約された場合を除き、お客様は、FCが次回のお支払い請求サイクルのメンバーシップ料金をお客様のお支払い方法に請求することを許可するものとします
                    (下記「キャンセル」参照)。
                  </li>
                  <li>
                    <span>(2)</span>
                    当社は、複数のメンバーシッププランをご提供する場合があります。第三者の製品およびサービスの提供と連動して第三者経由でメンバーシップをご提供することもあります。当社は、第三者が提供する製品およびサービスに責任を負いません。
                  </li>
                </ul>
              </div>
              <div className="lbox">
                <h3>2.キャンペーンオファー</h3>
                <ul>
                  <li>
                    当社では、特別なキャンペーンオファーやプラン、メンバーシップを提供することがあります
                    (「オファー」)。オファーご利用の可否は、FCがその単独の裁量により判断します。お客様にオファーのご利用資格がないと判断した場合、FCはオファーを取り消し、お客様のアカウントを保留にする権利を留保します。
                  </li>
                </ul>
              </div>
              <div className="lbox">
                <h3>3.お支払い請求とキャンセル</h3>
                <ul>
                  <li>
                    <span>(1)</span>
                    <em>お支払い請求サイクル</em>
                    <br />
                    FCサービスのメンバーシップ料金およびサービスの利用に関連して生じるその他の料金
                    (税金および適用ある場合は取引手数料等)
                    は、[マイページ]に記載されている特定の支払日に、お客様が登録されているお支払い方法に対して請求されます。お客様のお支払い請求サイクル期間は、FCサービス登録の際にお選びになったメンバーシップの種類によって異なります。ご登録のお支払い方法での決済が完了しなかった場合や、メンバーシッププランを変更した場合、お客様のメンバーシップ開始日が、該当する月内に存在しない暦日だった場合等には、お客様の支払日が変更されることがあります。但し、無料視聴期間は一切の請求が発生しないものといたします。FCメンバーシップの請求情報は、当該第三者にお持ちのアカウントにアクセスすることによって確認できます。
                  </li>
                  <li>
                    <span>(2)</span>
                    <em>お支払い方法</em>
                    <br />
                    FC有料視聴サービスをご利用になるには、1つ以上のお支払い方法を提供していただく必要があります。お客様の主要なお支払い方法が拒否された、もしくはお客様のメンバーシップ料金の支払いに利用できなくなった場合、お客様は、当社がお客様のアカウントに登録されているあらゆるお支払い方法に請求することを許可するものとします。お客様は、未決済金がある場合、当該金額について引き続き責任を負っていただきます。期限切れ、資金不足その他の理由でお支払いが決済できなかった場合で、お客様がアカウント解約を行われないときには、当社が有効なお支払い方法に請求できるようになるまでの間、お客様によるサービスへのアクセスを停止する場合があります。一部のお支払い方法に関しては、お支払い方法の発行者によって一定の料金
                    (外貨交換手数料やお支払い方法の処理に関連するその他の料金等)
                    が請求される場合があります。地方税額は、使用するお支払い方法によって異なる場合があります。詳細についてはお客様の決済サービスプロバイダーにご確認ください。
                  </li>
                  <li>
                    <span>(3)</span>
                    <em>お支払い方法の更新</em>
                    <br />
                    [マイページ]にアクセスすることにより、お支払い方法の更新が可能です。決済サービスプロバイダーが提供する情報を使用し、当社がお客様のお支払い方法を更新する場合もあります。更新後、お客様は、当社が該当するお支払い方法に引き続き請求を行うことを許可するものとします。
                  </li>
                  <li>
                    <span>(4)</span>
                    <em>キャンセル</em>
                    <br />
                    FCのメンバーシップはいつでも解約することができ、お客様はご請求期間の終了時まではそのままFCサービスへのアクセスが可能です。適用法により許容される範囲で、支払いは返金不可とし、メンバーシップ期間の途中の場合、または未利用のFCコンテンツがある場合についての払い戻しやクレジット付与は行いません。
                  </li>
                  <li>
                    <span>(5)</span>
                    <em>料金およびメンバーシッププランの変更</em>
                    <br />
                    FCは、当社メンバーシッププランおよび当社サービスの料金を随時変更することがありますが、いかなる料金変更またはお客様のメンバーシッププランの変更も、お客様に変更の通知を行ってから最低30日の期間をおいた後の適用となります。料金変更やメンバーシッププランの変更を承諾されない場合、変更が適用される前にお客様はメンバーシップをキャンセルすることができます。
                  </li>
                </ul>
              </div>
              <div className="lbox">
                <h3>4.FCサービス</h3>
                <ul>
                  <li>
                    <span>(1)</span>
                    FCサービスのメンバーになれるのは18歳以上の方に限ります。未成年者は成人の監督下でのみサービスを利用することができます。
                  </li>
                  <li>
                    <span>(2)</span>
                    FCサービスおよび当該サービスを通じてアクセスされるコンテンツは、お客様の個人的な非商業的用途に限るものとし、ご利用のメンバーシッププランで許可されていない限り、お客様のご世帯以外の方と共有することはできません。お客様のFCメンバーシップ期間中、FCはお客様に対して、FCサービスおよびFCコンテンツにアクセスするための限定的、非独占的、譲渡不可能な権利を付与します。上記を除き、お客様にはいかなる権利、所有権または権益も譲渡されません。お客様は公の場での上映のために当サービスを利用しないことに同意します。
                  </li>
                  <li>
                    <span>(3)</span>
                    FCコンテンツにアクセスできるのは、主としてお客様がアカウントを開設した国内、および当社がメンバーシッププランを提供し当該コンテンツのライセンスを付与した地理的場所に限るものとします。利用可能なコンテンツは地理的場所によって異なり、随時変更されます。広告つきメンバーシッププランでは一部のFCコンテンツがご利用いただけない場合があります。
                  </li>
                  <li>
                    <span>(4)</span>当社は定期的にFCサービス
                    (コンテンツライブラリを含みます)
                    の更新を行います。さらに当社は、当社のウェブサイト、ユーザーインターフェース、販促機能を含む当社サービスのさまざまな要素について継続的にテストを実施します。
                  </li>
                  <li>
                    <span>(5)</span>
                    ご利用のメンバーシッププランによっては、サポートされている特定のデバイスで一部のFCコンテンツを一時的にダウンロードし、オフライン視聴することができます
                    (「オフライン視聴可能作品」)。オフライン視聴に関しては、制限事項
                    (アカウントごとのオフライン視聴可能作品の数、オフライン視聴可能作品を保存できるデバイスの最大数、オフライン視聴可能作品をダウンロード後に視聴を開始しなくてはならない期間、およびオフライン視聴可能作品にアクセスできる期間に関する制約を含みます)
                    があります。一部の国では、オフライン視聴可能作品を再生できない場合があります。お客様がオフライン視聴可能作品をストリーミング再生できない国でインターネットに接続しても、その国に滞在する間はそれらのオフライン視聴可能作品を再生することができません。
                  </li>
                  <li>
                    <span>(6)</span>
                    お客様は、適用法、規則および規制や、サービスもしくはそのコンテンツの使用に関するその他の制限の一切にしたがってFCサービス
                    (関連する一切の機能を含みます)
                    を利用することに同意します。当社に明示的に認められていない限り、お客様は以下のことを行わないことに同意します。
                    <br />
                    <ul>
                      <li>
                        <span>(i)</span>
                        FCサービスに含まれる、または同サービスから、もしくは同サービスを通じて取得されるコンテンツおよび情報について、アーカイブ、複製、頒布、改ざん、展示、上演、出版、使用許諾、二次的著作物の創出、販売申し出、または使用を行うこと。
                      </li>
                      <li>
                        <span>(ii)</span>
                        FCサービスのコンテンツ保護やその他の要素
                        (グラフィカルユーザーインターフェース、広告または広告機能、著作権表示、商標など)
                        を回避、除去、変更、無効化、デグレード、ブロック、非表示、妨害すること。
                      </li>
                      <li>
                        <span>(iii)</span>
                        FCサービスへのアクセスのためにロボット、スパイダー、スクレイパー、その他の自動化手段を使用すること。
                      </li>
                      <li>
                        <span>(iv)</span>
                        FCサービスを通じてアクセス可能なソフトウェアまたはその他の製品もしくはプロセスの逆コンパイル、リバースエンジニアリングまたは逆アセンブルを行うこと。
                      </li>
                      <li>
                        <span>(v)</span>
                        何らかの形でFCサービスのコンテンツにコードもしくは製品を挿入したり、これを操作したりすること。
                      </li>
                      <li>
                        <span>(vi)</span>
                        何らかのデータマイニング、データ収集もしくは抽出方法を利用すること。
                      </li>
                      <li>
                        <span>(vii)</span>
                        FCサービスに関連するコンピュータソフトウェア・ハードウェアまたは電気通信機器の機能を遮断、破壊または制限するように作成されたマテリアル
                        (ソフトウェアウィルスやその他のコンピュータコード、ファイルまたはプログラムなど)
                        をアップロード、投稿、メール、その他の手段で送付または送信すること。お客様が本利用規約に違反したり、サービスを違法、不正または有害な方法で利用した場合、当社はお客様による当社サービスの利用を終了または制限する場合があります。
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>(7)</span>
                    FCコンテンツの画質はデバイスによって異なる場合があり、また、お客様の所在地、インターネット接続により利用可能な帯域幅やインターネット接続速度等のさまざまな要因によって影響を受ける場合があります。HD、UHDおよびHDRの利用可否はお客様のインターネットサービスおよびデバイス能力に依存します。また、すべてのコンテンツがHD、UHDまたはHDR等のあらゆるフォーマットで利用可能なわけではなく、すべてのメンバーシッププランで、あらゆるフォーマットのコンテンツの配信を受けられるわけではありません。HD画質
                    (解像度720p以上と定義されます)
                    の最低接続速度はストリームあたり3.0
                    Mbpsです。ただし、動画の品質向上のためにはこれよりも速い速度を推奨します。フルHD画質
                    (解像度1080p以上と定義されます)
                    の受信には、ストリームあたり最低5.0
                    Mbpsの接続速度が推奨されます。UHD画質
                    (解像度4K以上と定義されます)
                    の受信には、ストリームあたり最低15.0
                    Mbpsの接続速度が推奨されます。インターネットアクセスに必要な料金の一切はお客様によるご負担とします。インターネットデータ使用量に対して課される可能性のある料金に関しては、ご利用のインターネットプロバイダーにご確認ください。FCコンテンツの視聴開始までの所要時間は、お客様の所在地、その時点で利用可能な帯域幅、選択したコンテンツ、およびお客様のFC対応デバイスの設定等、数々の要因によって異なります。
                  </li>
                  <li>
                    <span>(8)</span>
                    FCソフトウェアはFCによって、またはFCのために開発されたものであり、その使用目的は、FC対応デバイスでFCコンテンツを正当な方法によりストリーミングする、またはこれにアクセスすることに限られます。ソフトウェアはデバイスおよび媒体によって異なる場合があり、その機能もデバイスによって異なる場合があります。お客様は、サービスのご利用に、第三者によるライセンス対象である第三者のソフトウェアが必要となる場合があることを了解します。お客様は、FCソフトウェアおよび関連する第三者のソフトウェアの更新版を自動的に受領する場合があることに同意します。
                  </li>
                </ul>
              </div>
              <div className="lbox">
                <h3>5.パスワードとアカウントアクセス</h3>
                <ul>
                  <li>
                    FCアカウント経由で発生するあらゆるアクティビティについては、お客様が責任を負うものとします。他者に対してアカウントにアクセスすることを許可した場合
                    (アカウントの視聴履歴の情報へのアクセスを含みます)、お客様は、それらの個人がお客様の代理として行動しており、それらの個人が当該アカウントに対して行う変更
                    (メンバーシッププランに対する変更を含みますがそれに限りません)
                    にお客様が拘束を受けることに同意したと見なされます。アカウントの制御を維持し、無断使用者によるアカウント
                    (アカウントの視聴履歴の情報を含みます)
                    へのアクセスを防ぐために、お客様は、サービスにアクセスするために使用するデバイスの管理を維持し、アカウントのパスワードやお支払い方法の詳細を他者へ開示しないことが推奨されます。お客様は、ご自身のアカウントに関連して正確な情報を提供および維持することに同意します。これには、当社からお客様にアカウント関連の通知を送信できるように、有効なメールアドレスを提供することも含まれます。当社は、お客様、FCまたは当社のパートナー社を個人情報の盗難またはその他の不正行為から保護するために、お客様のアカウントを解約または一時的に保留とすることができます。
                  </li>
                </ul>
              </div>
              <div className="lbox">
                <h3>6.雑則</h3>
                <ul>
                  <li>
                    <span>(1)</span>
                    <em>準拠法</em>
                    <br />
                    本利用規約は日本法に準拠し、同法にしたがって解釈されます。
                  </li>
                  <li>
                    <span>(2)</span>
                    <em>持ち込み企画</em>
                    <br />
                    FCは、FCコンテンツに関する持ち込み資料やアイディアを受け取らず、また、いかなる媒体においてもFCのコンテンツまたは作品と、FCに送信された資料やアイディアとの類似性につき責任を負いません。
                  </li>
                  <li>
                    <span>(3)</span>
                    <em>カスタマーサポート</em>
                    <br />
                    当社サービスおよびその機能の詳細、またはお客様のアカウントへのサポートについては、FCヘルプセンターにアクセスしご参照ください。場合によっては、カスタマーサービスがお客様のコンピュータに全面的にアクセスできる遠隔アクセスサポートツールを使用してサポートすることが最善となる場合があります。当社からのかかるアクセスをご希望でない場合には、遠隔アクセスツールによるサポートには同意しないでください。この場合、当社は別の方法でサポートを行います。本利用規約とカスタマーサービスによって提供された情報または当社ウェブサイトのその他の箇所に齟齬がある場合、本利用規約が優先します。
                  </li>
                  <li>
                    <span>(4)</span>
                    <em>利用規約の変更</em>
                    <br />
                    FCは本利用規約を随時変更することがあります。当社は、そういった変更がお客様に適用される日の30日前までにこれをお客様に通知します。そういった変更を承諾されない場合、変更が適用される前にお客様はメンバーシップをキャンセルすることができます。
                  </li>
                  <li>
                    <span>(5)</span>
                    <em>電子通信</em>
                    <br />
                    当社はお客様のアカウントに関する情報
                    (お支払いの承認、請求書、パスワードやお支払い方法の変更、確認メッセージ、通知等)
                    を、会員登録時に提供されたお客様のメールアドレスを宛先としたメール等、電子的形態でのみお客様に送付します。
                  </li>
                  <li>
                    <span>(6)</span>
                    <em>保証および責任の限定</em>
                    <br />
                    FCサービスは「現状有姿」で、保証なしで提供されます。特に、当社サービスには中断やエラーが生じる可能性があります。お客様は、当社に対する特別および結果的損害賠償請求や、1ヵ月分のメンバーシップ料金を超える直接的な損害賠償請求を放棄するものとします。ご利用のメンバーシッププランによっては、FCサービス上でお客様に第三者の広告が表示されることがあります。FCはいかなる広告対象の商品やサービスも推奨または支援しておらず、お客様による広告主とのやりとり
                    (インタラクティブ広告に対する操作を含みます)
                    は、お客様ご自身の判断および責任において行うものとします。本利用規約は、お客様が居住されている国の法律に基づき認められている放棄不能の保証または消費者保護権を制限するものではありません。
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div id="footer_in">
            <div className="f_menu">
              <Link to={PATH.LEGAL} title="利用規約">
                利用規約
              </Link>
              ｜
              <Link to={PATH.LEGALNOTICEL} title="特定商取引法に関する表示">
                特定商取引法に関する表示
              </Link>
              ｜
              <Link to={PATH.PRIVACY} title="プライバシーポリシー">
                プライバシーポリシー
              </Link>
            </div>
          </div>
        </footer>
      </ContainerLegalStyled>
    </WrapperTermsStyled>
  );
};

const ContainerLegalStyled = styled.div`
  .s_title {
    margin-bottom: 30px;
  }

  /** legal **/

  #legal {
    width: 940px;
    margin: 0 auto 120px auto;
  }
  #legal:last-of-type {
    margin-bottom: 0;
  }

  #legal .toptxt {
    font-size: 10px;
    line-height: 1.2;
    text-align: left;
    margin: 0 0 30px 0;
  }
  #legal .lbox {
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
    text-align: left;
    border-bottom: 1px dotted #333;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  #legal .lbox:last-of-type {
    margin-bottom: 0;
  }
  #legal .lbox h3 {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.2;
    color: #ffe400;
    margin: 0 0 10px 0;
  }
  #legal .lbox > ul {
  }
  #legal .lbox > p {
    font-size: 10px;
    line-height: 1.2;
  }
  #legal .lbox > ul > li {
    position: relative;
    font-size: 10px;
    line-height: 1.2;
    margin: 0 0 10px 0;
    padding: 0 0 0 30px;
  }
  #legal .lbox > ul > li:last-of-type {
    margin-bottom: 0;
  }
  #legal .lbox > ul > li span {
    position: absolute;
    top: 0;
    left: 0;
  }
  #legal .lbox > ul li > ul {
    margin: 15px 0 20px 0;
  }
  #legal .lbox > ul li > ul li {
    position: relative;
    font-size: 10px;
    line-height: 1.2;
    margin: 0 0 5px 0;
    padding: 0 0 0 25px;
  }
  #legal .lbox > ul li > ul li:last-of-type {
    margin-bottom: 0;
  }

  /*=================================================
 * CSS for SP
 * ================================================= */

  @media screen and (max-width: 750px) {
    .s_title {
      margin-bottom: 20px;
    }

    /** legal **/
    #legal {
      width: auto;
      margin: 0 0 80px 0;
      padding: 0 15px;
    }
  }
`;
