/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { useMqtt } from 'app/hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  unstable_HistoryRouter as HistoryRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import styled, { ThemeProvider } from 'styled-components';
import history from 'utils/history';

// component
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStyle } from 'styles/global-styles';
import { PATH } from './constants/path';

//page
import { Modal, Typography } from 'antd';
import { MetaToast } from 'app/components/Modals/MetaToast';
import useToken from 'app/hooks/useToken';
import {
  ForgotPassword,
  RegisterVerification,
  ResetPassword,
  SignInUpPage,
} from 'app/pages/AuthPage';
import { useAuthSlice } from 'app/pages/AuthPage/slice';
import { selectAuth } from 'app/pages/AuthPage/slice/selectors';
import { HomePage } from 'app/pages/HomePage';
import { ProfileEdit, ProfileView } from 'app/pages/Profile/';
import { LiveStreamPage, LiveStreamViewerPage } from 'app/pages/StreamPage';
import { _log } from 'utils/_log';
import {
  IS_ANDROID,
  IS_CHROME,
  IS_IOS,
  IS_IPAD,
  IS_IPHONE,
} from 'utils/constants';
import MainLayout from './components/Layouts';
import ReloadPage from './pages/ReloadPage';
import { selectToast } from './pages/ToastPage/slice/selectors';
import PaymentSuccessful from './pages/PaymentNotification/PaymentSuccess';
import { Legal } from './pages/TermsPage/Legal';
import { LegalNoticel } from './pages/TermsPage/LegalNoticel';
import { Privacy } from './pages/TermsPage/Privacy';
import PaymentProcessing from './pages/PaymentNotification/PaymentProcessing';
import PaymentFailed from './pages/PaymentNotification/PaymentFailed';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

const Layout = () => {
  const { token } = useToken();
  const pathname = window.location.pathname;

  if (!token) {
    if (pathname.includes(PATH.STREAM) && pathname.split('/')?.length > 1) {
      return (
        <Navigate
          to={`${PATH.LOGIN}?username=${pathname.split('/')[2]}`}
          replace
        />
      );
    } else {
      return <Navigate to={PATH.LOGIN} replace />;
    }
  }
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

export function App() {
  const dispatch = useDispatch();
  const { actions } = useAuthSlice();
  const { currentUser } = useSelector(selectAuth);
  const {
    isOpen,
    message,
    type,
    titleButton,
    urlRedirect,
    handleFunc,
    isErrorToken,
    imageElement,
    buttonElement,
    titleCustom,
    children,
    isCloseOkCustom,
  } = useSelector(selectToast);
  const [isLandScaped, setIsLandScaped] = React.useState(false);
  const [theme] = React.useState({
    color_hover: '#EB7D1785',
    color_primary: '#FF347D',
    color_secondary: '#F4887B',
    color_warning: '#E10000',
    color_success: '#28a745',
    color_confirm: '#1677ff',
  });

  useMqtt({
    topicName: `payment/user/${currentUser?.id}`,
    callback: onPaymentSuccessEvent,
  });

  function onPaymentSuccessEvent(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const { data } = msg;

      const dataParse = JSON.parse(data);

      const textToast =
        Number(dataParse?.total) < 0 ? 'Deducted ' : 'Purchase ';

      toast.success(
        textToast + Math.abs(dataParse?.total) + ' point successfully',
      );

      dispatch(actions.getUser({}));
      dispatch(actions.setIsUpdatePurchasePoint(true));
    } catch (error) {
      _log('onPaymentSuccessEvent error: ', error, 'error');
    }
  }

  function handleOrientationChange() {
    if (
      window?.screen?.orientation?.angle === 90 ||
      window?.screen?.orientation?.angle === 270
    ) {
      setIsLandScaped(true);
    } else {
      setIsLandScaped(false);
    }
  }

  function _handleOrientationChangeDeprecated() {
    if (window.orientation === 90 || window.orientation === -90) {
      setIsLandScaped(true);
    } else {
      setIsLandScaped(false);
    }
  }

  function handleOrientationIPadChange() {
    if (
      window?.screen?.orientation?.angle === 90 ||
      window?.screen?.orientation?.angle === 270
    ) {
      setIsLandScaped(true);
    } else {
      setIsLandScaped(false);
    }
  }

  function _handleOrientationIPadChangeDeprecated() {
    if (window.orientation === 180 || window.orientation === 0) {
      setIsLandScaped(true);
    } else {
      setIsLandScaped(false);
    }
  }

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (IS_ANDROID || IS_IPHONE || IS_CHROME) {
        handleOrientationChange();

        _handleOrientationChangeDeprecated();

        window?.screen?.orientation?.addEventListener(
          'change',
          handleOrientationChange,
        );

        window.addEventListener(
          'orientationchange',
          _handleOrientationChangeDeprecated,
        );

        return () => {
          window?.screen?.orientation?.removeEventListener(
            'change',
            handleOrientationChange,
          );

          window.removeEventListener(
            'orientationchange',
            _handleOrientationChangeDeprecated,
          );
        };
      }
      if (IS_IPAD) {
        handleOrientationIPadChange();

        _handleOrientationIPadChangeDeprecated();

        window?.screen?.orientation?.addEventListener(
          'change',
          handleOrientationIPadChange,
        );

        window.addEventListener(
          'orientationchange',
          _handleOrientationIPadChangeDeprecated,
        );

        return () => {
          window?.screen?.orientation?.removeEventListener(
            'change',
            handleOrientationIPadChange,
          );

          window.removeEventListener(
            'orientationchange',
            _handleOrientationIPadChangeDeprecated,
          );
        };
      }
    }
  }, [window]);

  React.useEffect(() => {
    /**
     * Old flow
     */
    // if (
    //   !window.location.pathname.includes(PATH.PROFILE_EDIT) &&
    //   currentUser?.avatar3d_url === null &&
    // ) {
    //   window.location.href = PATH.LIST_STREAM;
    // }
    /**
     * New flow
     * No.36
     * https://cmcglobalcompany.sharepoint.com/:x:/r/sites/CMCGlobal/DuAnCMCG/2023/CJP_DJ7_Zept%20LLC%20(Zept%E5%90%88%E5%90%8C%E4%BC%9A%E7%A4%BE)_Livestream%20on%20Metaverse/N01%20-%20Customer%27s%20Requirements/A.%20Original/FB_KH/%E3%80%90Livestream%20Meta%E3%80%91%20Feedback%20KH.xlsx?d=w2b3d517d3ab54fa4b24ad950003ee0d2&csf=1&web=1&e=dxM3KQ&nav=MTZfVmlldzFfezhDRTlFNUYwLUFEOTItNDQ3RS1BN0Q1LTIyQ0JFRTU0QUZFMX0
     */
  }, [currentUser]);

  //  Google Analytic
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-23TFE6MZSX';
    script.async = true;

    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag(...args: any[]): void {
      window.dataLayer.push(...args);
    };

    window.gtag('js', new Date());
    window.gtag('config', 'G-23TFE6MZSX');

    return () => {
      head.removeChild(script);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <HistoryRouter history={history}>
        <Routes>
          <Route
            path={PATH.LEGAL}
            element={
              <>
                <Legal />
                <ChangeOrientationModal isLandScaped={isLandScaped} />
              </>
            }
          />
          <Route
            path={PATH.LEGALNOTICEL}
            element={
              <>
                <LegalNoticel />
                <ChangeOrientationModal isLandScaped={isLandScaped} />
              </>
            }
          />
          <Route
            path={PATH.PRIVACY}
            element={
              <>
                <Privacy />
                <ChangeOrientationModal isLandScaped={isLandScaped} />
              </>
            }
          />
          <Route
            path={PATH.LOGIN}
            element={
              <>
                <SignInUpPage />
                <ChangeOrientationModal isLandScaped={isLandScaped} />
              </>
            }
          />
          <Route
            path={PATH.REGISTER}
            element={
              <>
                <SignInUpPage />
                <ChangeOrientationModal isLandScaped={isLandScaped} />
              </>
            }
          />
          <Route
            path={PATH.FORGOT_PASSWORD}
            element={
              <>
                <ForgotPassword />
                <ChangeOrientationModal isLandScaped={isLandScaped} />
              </>
            }
          />
          <Route
            path={PATH.RESET_PASSWORD}
            element={
              <>
                <ResetPassword />
                <ChangeOrientationModal isLandScaped={isLandScaped} />
              </>
            }
          />
          <Route
            path={PATH.REGISTER_VERIFICATION}
            element={
              <>
                <RegisterVerification />
                <ChangeOrientationModal isLandScaped={isLandScaped} />
              </>
            }
          />
          <Route
            path={PATH.PAYMENT_SUCCESSFUL}
            element={
              <>
                <PaymentSuccessful />
                <ChangeOrientationModal isLandScaped={isLandScaped} />
              </>
            }
          />
          <Route
            path={PATH.PAYMENT_FAILED}
            element={
              <>
                <PaymentFailed />
                <ChangeOrientationModal isLandScaped={isLandScaped} />
              </>
            }
          />
          <Route
            path={PATH.PAYMENT_PROCESSING}
            element={
              <>
                <PaymentProcessing />
                <ChangeOrientationModal isLandScaped={isLandScaped} />
              </>
            }
          />

          <Route path={'/'} element={<Layout />}>
            <Route
              path={PATH.HOME}
              element={
                <>
                  <HomePage />
                  <ChangeOrientationModal isLandScaped={isLandScaped} />
                </>
              }
            />

            <Route
              path={PATH.LIST_STREAM}
              element={
                <>
                  <HomePage />
                  <ChangeOrientationModal isLandScaped={isLandScaped} />
                </>
              }
            />

            <Route
              path={PATH.STREAM}
              element={
                <>
                  <LiveStreamPage />
                  <ChangeOrientationModal isLandScaped={isLandScaped} />
                </>
              }
            />

            <Route
              path={PATH.STREAM_DASH}
              element={
                <>
                  <LiveStreamPage />
                  <ChangeOrientationModal isLandScaped={isLandScaped} />
                </>
              }
            />

            <Route
              path={PATH.STREAM_VIEWER}
              element={
                <>
                  <LiveStreamViewerPage />
                  <ChangeOrientationModal isLandScaped={isLandScaped} />
                </>
              }
            />

            <Route
              path={PATH.PROFILE}
              element={
                <>
                  <ProfileView />
                  <ChangeOrientationModal isLandScaped={isLandScaped} />
                </>
              }
            />

            <Route
              path={PATH.PROFILE_PERSON}
              element={
                <>
                  <ProfileView />
                  <ChangeOrientationModal isLandScaped={isLandScaped} />
                </>
              }
            />

            <Route
              path={PATH.PROFILE_EDIT}
              element={
                <>
                  <ProfileEdit />
                  <ChangeOrientationModal isLandScaped={isLandScaped} />
                </>
              }
            />

            <Route
              path={PATH.RELOAD}
              element={
                IS_IOS ? (
                  <>
                    <ReloadPage />
                    <ChangeOrientationModal isLandScaped={isLandScaped} />
                  </>
                ) : null
              }
            />
          </Route>
        </Routes>
        <GlobalStyle />
        <ToastContainer />
        <MetaToast
          isOpen={isOpen}
          typeToast={type ?? 'success'}
          message={message}
          titleButton={titleButton}
          urlRedirect={urlRedirect}
          handleFunc={handleFunc}
          isErrorToken={isErrorToken}
          imageElement={imageElement}
          buttonElement={buttonElement}
          titleCustom={titleCustom}
          children={children}
          isCloseOkCustom={isCloseOkCustom}
        />
      </HistoryRouter>
    </ThemeProvider>
  );
}

function ChangeOrientationModal({ isLandScaped }: { isLandScaped: boolean }) {
  return (
    <ModalContainer
      open={isLandScaped}
      title=""
      footer={null}
      centered
      wrapClassName="modal-rotate-screen-wrapper"
    >
      <Typography.Title
        level={5}
        style={{ textAlign: 'center', marginTop: '8px' }}
      >
        Please rotate your screen to {IS_IPAD ? 'landscape' : 'portrait'}{' '}
        orientation for the best experience.
      </Typography.Title>
    </ModalContainer>
  );
}

const ModalContainer = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
`;
