import styled from 'styled-components';
import { LogoTerms } from 'styles/image';

export const WrapperTermsStyled = styled.div`
  /*=================================================
 * CSS for PC
 * ================================================= */

  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-family: Meiryo, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', sans-serif !important;
  min-width: 1100px;
  color: #fff;
  background: #0f0f0f;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  body,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  label,
  span,
  a,
  div {
    font-family: Meiryo, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans',
      sans-serif !important;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  a[href^='tel:'] {
    cursor: default;
    text-decoration: none;
    color: #fff;
  }
  a[href^='tel:']:hover {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 13px;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  h2 {
    line-height: 0;
  }

  ul {
    list-style-type: none;
  }

  li {
    margin: 0px;
    padding: 0px;
  }

  table {
    font-size: 13px;
  }

  table tr {
    vertical-align: top;
  }

  i,
  em {
    font-style: normal;
  }

  div,
  p,
  ul,
  li {
    margin: 0;
    padding: 0;
  }

  /** header **/
  header {
    text-align: center;
    padding: 30px 0 0 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  h1 {
    font-size: 10px;
    font-weight: normal;
    line-height: 1;
    text-align: right;
    color: #666;
    position: absolute;
    top: -100vh;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  header #h_logo {
    display: inline-block;
    vertical-align: top;
    font-size: 0;
    line-height: 0;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  header #h_logo:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
  header #h_logo img {
    width: 180px;
    height: auto;
  }

  /** main **/

  main {
    position: relative;
    display: block;
  }

  #cnt {
    width: auto;
    margin: 0 auto;
    padding: 30px 0 50px 0;
  }

  /** footer **/

  footer {
    text-align: center;
    color: #fff;
    border-top: 1px solid #333;
    padding: 0 0 50px 0;
    background: url(${LogoTerms}) no-repeat 50% calc(100% - 30px);
    background-size: 100px auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .f_menu {
    font-size: 11px;
    line-height: 18px;
    padding: 20px 0;
  }
  .f_menu a,
  .f_menu span {
    color: #fff;
  }

  /*=================================================
 * CSS for SP
 * ================================================= */

  @media screen and (max-width: 750px) {
    min-width: 0;

    a[href^='tel:'] {
      cursor: pointer;
    }
    a:hover {
      text-decoration: none;
    }

    #cnt {
      width: auto;
      margin: 0;
    }
  }

  /*=================================================
 * CSS for PC
 * ================================================= */

  /***************************************
TITLE
****************************************/

  .s_title {
    position: relative;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .s_title:after {
    content: '';
    width: 570px;
    border-bottom: 3px double #333;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 285px);
  }
  .s_title em {
    position: relative;
    display: block;
    font-size: 26px;
    line-height: 34px;
    margin: 0 0 5px 0;
  }
  .s_title em i {
    font-style: normal;
    color: #ff0000;
  }

  .s_title span {
    position: relative;
    z-index: 2;
    display: inline-block;
    vertical-align: top;
    font-family: 'FontA';
    font-size: 10px;
    line-height: 20px;
    background: #333;
    padding: 0 10px;
    border-radius: 10px;
  }

  /***************************************
COMMON STYLE
****************************************/

  body {
    color: #fff;
  }

  /*=================================================
 * CSS for SP
 * ================================================= */

  @media screen and (max-width: 750px) {
    /***************************************
	TITLE
	****************************************/
    .s_title {
    }
    .s_title:after {
      content: '';
      width: 250px;
      position: absolute;
      bottom: 9px;
      left: calc(50% - 125px);
    }
    .s_title em {
      position: relative;
      display: block;
      font-size: 24px;
      line-height: 28px;
    }
  }
`;
