import React from 'react';

import { PaymentSuccessIcon } from 'styles/image';
import { MetaButton } from 'app/components/Forms';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'app/constants/path';
import { Container } from '../styles/styles';

const PaymentSuccessful = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <img src={PaymentSuccessIcon} alt="" style={{ width: '400px' }} />

      <h2
        style={{
          color: '#1F2937',
          fontSize: '34px',
          fontWeight: '700',
          marginBottom: '40px',
        }}
      >
        お支払い完了
      </h2>

      <MetaButton
        type="primary"
        text="確定​"
        style={{ height: '52px', fontSize: '18px', margin: '0px auto' }}
        onClick={() => navigate(PATH.PROFILE)}
      />
    </Container>
  );
};

export default PaymentSuccessful;
