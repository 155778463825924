import React from 'react';
import styled from 'styled-components';
import { WrapperTermsStyled } from '../CommonCss/styles';
import { Link } from 'react-router-dom';
import { PATH } from 'app/constants/path';
import { LogoTerms } from 'styles/image';

export const Privacy = () => {
  return (
    <WrapperTermsStyled>
      <ContainerPrivacyStyled>
        <header>
          <Link to={PATH.HOME} id="h_logo" title="FU-CHA（フーチャ）">
            <img src={LogoTerms} alt="FU-CHA（フーチャ）" />
          </Link>
        </header>
        <main>
          <div id="cnt">
            <div id="privacy">
              <h2 className="s_title">
                <em>プライバシーポリシー</em>
                <span>PRIVACY POLICY</span>
              </h2>
              <p className="toptxt t">
                有限会社ブラックライン（以下「当社」といいます）は、当社が取得した個人情報の取扱いに関し、個人情報の保護に関する法律、個人情報保護に関するガイドライン等の指針、その他個人情報保護に関する関係法令を遵守します。
              </p>
              <div className="lbox">
                <h3>1.取得する情報およびその取得方法、利用目的</h3>
                <p>
                  当社が取得するユーザー情報は、取得方法に応じて以下のとおりとなります。
                </p>
                <ul>
                  <li>
                    <em>(1)ユーザーから直接取得する情報と取得方法</em>
                    当社は、当社が提供するインターネットサイト（以下「本サイト」といいます）の運営に必要な範囲で、本サイトの利用者（以下「ユーザー」といいます）から、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人時期別情報）（以下「個人情報」といいます）を取得することがあります。
                    <br />
                  </li>
                  <li>
                    <em>(2)情報の利用目的</em>
                    当社は、取得した個人情報を以下に定める目的のために使用します。
                    <br />
                    <p>
                      <span>※</span>
                      ここでは、商品・サービスの提供、利用料金の請求、ユーザーの本人確認など、個人情報の利用目的を記載
                    </p>
                  </li>
                </ul>
              </div>
              <div className="lbox">
                <h3>2.個人情報の管理</h3>
                <p>
                  当社は、ユーザーから取得した個人情報の管理について、以下を徹底します。
                </p>
                <ul>
                  <li>
                    <em>(1)情報の正確性の確保</em>
                    取得した個人情報については、常に正確かつ最新の情報となるよう努めます。
                    <br />
                  </li>
                  <li>
                    <em>(2)安全管理措置</em>
                    当社は、組織的な個人情報の管理については、社内規定による厳重な取扱方法を規定し、規定に基づいた取扱いと厳格な運用を徹底しています。
                    <br />
                  </li>
                  <li>
                    <em>(3)個人情報管理の委託先の監督</em>
                    個人情報の管理を外部委託する場合には、当社の規程に基づく委託先にのみ委託し適切に管理します。
                    <br />
                  </li>
                  <li>
                    <em>(4)個人情報の保存期間と廃棄</em>
                    取得した個人情報は、保存期間を設定し、保存期間終了後は廃棄します。
                    <br />
                  </li>
                </ul>
              </div>
              <div className="lbox">
                <h3>3.個人情報の第三者への提供</h3>
                <p>
                  当社は、取得した個人情報を、第三者に提供することはありません。また、今後、第三者提供を行う場合は、提供する情報と目的を提示し、ユーザーの同意を得た場合のみ行います。
                  <br />
                </p>
              </div>
              <div className="lbox">
                <h3>4.個人情報の共同利用</h3>
                <p>
                  当社は、ユーザーの個人情報に関して、以下のとおり共同利用します。
                  <br />
                </p>
              </div>
              <div className="lbox">
                <h3>5.個人情報の開示・訂正・利用停止</h3>
                <p>
                  個人情報について、開示、訂正、利用停止等のお申し出があった場合には、本人の申し出であることを確認の上、当社所定の方法に基づき対応致します。具体的な方法は、個別にご案内しますので、J
                  <br />
                </p>
              </div>
              <div className="lbox">
                <h3>6.お問い合わせ先</h3>
                <p>
                  本サービス、個人情報の取扱いについては、FU-CHAカスタマーサービス：aaaaaa@fu-cha.netにご連絡ください。
                  <br />
                </p>
              </div>
              <div className="lbox">
                <h3>7.セキュリティ</h3>
                <p>
                  当社は、ウェブサイト経由で、SSLによって個人情報を取得することがあります。
                  <br />
                </p>
              </div>
              <div className="lbox">
                <h3>8.制定日、改定日</h3>
                <p>
                  制定日 2024年11月1日
                  <br />
                </p>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div id="footer_in">
            <div className="f_menu">
              <Link to={PATH.LEGAL} title="利用規約">
                利用規約
              </Link>
              ｜
              <Link to={PATH.LEGALNOTICEL} title="特定商取引法に関する表示">
                特定商取引法に関する表示
              </Link>
              ｜
              <Link to={PATH.PRIVACY} title="プライバシーポリシー">
                プライバシーポリシー
              </Link>
            </div>
          </div>
        </footer>
      </ContainerPrivacyStyled>
    </WrapperTermsStyled>
  );
};

const ContainerPrivacyStyled = styled.div`
  .s_title {
    margin-bottom: 30px;
  }

  /** privacy **/

  #privacy {
    width: 940px;
    margin: 0 auto 120px auto;
  }
  #privacy:last-of-type {
    margin-bottom: 0;
  }

  #privacy .toptxt {
    font-size: 10px;
    line-height: 1.2;
    text-align: left;
    margin: 0 0 30px 0;
  }
  #privacy .lbox {
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
    text-align: left;
    border-bottom: 1px dotted #333;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  #privacy .lbox:last-of-type {
    margin-bottom: 0;
  }
  #privacy .lbox h3 {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.2;
    color: #ffe400;
    margin: 0 0 10px 0;
  }
  #privacy .lbox > ul {
    padding-left: 9px;
  }
  #privacy .lbox > p {
    font-size: 10px;
    line-height: 1.2;
    margin: 0 0 10px 0;
  }
  #privacy .lbox > p:last-child {
    margin-bottom: 0;
  }
  #privacy .lbox > ul > li {
    position: relative;
    font-size: 10px;
    line-height: 1.2;
    margin: 0 0 10px 0;
  }
  #privacy .lbox > ul > li:last-of-type {
    margin-bottom: 0;
  }
  #privacy .lbox > ul > li em {
    display: block;
    font-weight: bold;
    margin: 0 0 5px 0;
  }

  /*=================================================
 * CSS for SP
 * ================================================= */

  @media screen and (max-width: 750px) {
    .s_title {
      margin-bottom: 20px;
    }

    /** privacy **/
    /** privacy **/
    #privacy {
      width: auto;
      margin: 0 0 80px 0;
      padding: 0 15px;
    }
  }
`;
