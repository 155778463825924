import styled from 'styled-components';
import { loginBg } from 'styles/image';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  flex: 1;
  position: relative;
  background-image: url('${loginBg}');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;

  @media only screen and (max-width: 1024px) {
    background-image: none;
    background-color: #231f1f;
  }
`;
