import React from 'react';
import styled from 'styled-components';
import { WrapperTermsStyled } from '../CommonCss/styles';
import { Link } from 'react-router-dom';
import { PATH } from 'app/constants/path';
import { LogoTerms } from 'styles/image';

export const LegalNoticel = () => {
  return (
    <WrapperTermsStyled>
      <ContainerLegalNoticelStyled>
        <header>
          <Link to={PATH.HOME} id="h_logo" title="FU-CHA（フーチャ）">
            <img src={LogoTerms} alt="FU-CHA（フーチャ）" />
          </Link>
        </header>
        <main>
          <div id="cnt">
            <div id="legalnoticel" className="linfo">
              <h2 className="s_title">
                <em>特定商取引法に関する表示</em>
                <span>LEGAL NOTICE</span>
              </h2>
              <dl>
                <dt>販売業者</dt>
                <dd>有限会社ブラックライン</dd>
              </dl>
              <dl>
                <dt>販売責任者</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>所在地</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>メールアドレス</dt>
                <dd></dd>
              </dl>
              <dl>
                <dt>販売代金</dt>
                <dd>購入手続きの際に画面に表示されます</dd>
              </dl>
              <dl>
                <dt>商品代金以外の必要金額</dt>
                <dd>
                  ウェブページの閲覧、デジタルコンテンツやソフトウェアのダウンロード等に必要となるインターネット接続料金、通信料金等はお客様の負担となります。それぞれの料金は、お客様がご利用のインターネットプロバイダーまたは携帯電話会社にお問い合わせください。
                </dd>
              </dl>
              <dl>
                <dt>お支払い方法</dt>
                <dd>クレジットカードのみとなります。</dd>
              </dl>
              <dl>
                <dt>
                  デジタルコンテンツ等が
                  <br />
                  利用可能となる時期
                </dt>
                <dd>
                  購入に関するページに特別な定めを置いている場合を除き、購入手続の完了後、直ちにご利用いただけます。
                </dd>
              </dl>
              <dl>
                <dt>キャンセル</dt>
                <dd>商品の特性上、キャンセルは一切お受けできません。</dd>
              </dl>
              <dl>
                <dt>保証</dt>
                <dd>
                  当社は販売した商品の不備または不具合等について、一切保証しません。
                </dd>
              </dl>
              <dl>
                <dt>返品・交換について</dt>
                <dd>商品の特性上、返品・交換は一切お受けできません。</dd>
              </dl>
              <dl>
                <dt>サービスの推奨環境</dt>
                <dd>
                  <ul>
                    <li>
                      【PC】OS : Windows 10、Mac OS X 10.11以降、ブラウザ :
                      Google Chrome最新版、Safari最新版
                    </li>
                    <li>
                      【スマートフォン】OS : iOS 13.0以降、Android
                      8.0以降、ブラウザ : Google Chrome最新版 、Safari最新版
                    </li>
                  </ul>
                </dd>
              </dl>
              <dl>
                <dt>その他の事項</dt>
                <dd>
                  お客様が未成年の場合は、事前に親権者の同意を得てご利用ください。
                  <br />
                  決済エラーなどによりご返金をご希望の場合は、決済日時、ご登録のメールアドレス、領収番号の３点を添えてご連絡お願いいたします。
                  <br />
                </dd>
              </dl>
            </div>
          </div>
        </main>
        <footer>
          <div id="footer_in">
            <div className="f_menu">
              <Link to={PATH.LEGAL} title="利用規約">
                利用規約
              </Link>
              ｜
              <Link to={PATH.LEGALNOTICEL} title="特定商取引法に関する表示">
                特定商取引法に関する表示
              </Link>
              ｜
              <Link to={PATH.PRIVACY} title="プライバシーポリシー">
                プライバシーポリシー
              </Link>
            </div>
          </div>
        </footer>
      </ContainerLegalNoticelStyled>
    </WrapperTermsStyled>
  );
};

const ContainerLegalNoticelStyled = styled.div`
  .s_title {
    margin-bottom: 30px;
  }

  /** legalnoticel **/

  #legalnoticel {
    margin-bottom: 50px;
  }
  .linfo {
  }
  .linfo h3 {
    width: calc(100% - 30px);
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
    padding: 10px 15px;
    border: 1px solid #333;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .linfo dl {
    width: 670px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    font-size: 10px;
    line-height: 1.2;
    text-align: left;
    border-bottom: 1px dotted #333;
    margin: 0 auto;
    padding: 10px 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .linfo dl:first-of-type {
    padding-top: 0;
  }
  .linfo dt {
    width: 140px;
    color: #ffe400;
  }
  .linfo dd {
    width: calc(100% - 140px);
    margin: 0;
    padding: 0;
  }

  .linfo dd li {
    position: relative;
    margin: 0 0 5px 0;
  }
  .linfo dd li:last-of-type {
    margin-bottom: 0;
  }

  /*=================================================
 * CSS for SP
 * ================================================= */

  @media screen and (max-width: 750px) {
    .s_title {
      margin-bottom: 20px;
    }

    /** legalnoticel **/
    .linfo {
      padding: 0 15px;
    }
    .linfo h3 {
      width: 100%;
      font-size: 15px;
      line-height: 20px;
      padding: 10px 15px;
    }
    .linfo dl {
      width: auto;
      display: block;
    }
    .linfo dt {
      width: auto;
      margin: 0 0 5px 0;
      padding: 0;
    }
    .linfo dt br {
      display: none;
    }
    .linfo dd {
      width: auto;
      margin: 0;
      padding: 0;
    }
  }
`;
