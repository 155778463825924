import { purchasePoint } from 'app/apis/point';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { actionsToast } from 'app/pages/ToastPage/slice';
import { ClockIcon } from 'styles/image';
import { useMediaQuery } from 'react-responsive';

const positiveRegex = /^\d+$/;

const positiveExcludeZeroRegex = /^[1-9]\d*$/;

export const usePointPurchase = ({
  setShowPointPopup,
  pointPakageData,
}: any) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: '640px' });

  const [pointPakageSelect, setPointPakageSelect] = useState<any>({});

  const [pakageQuantity, setPakageQuantity] = useState(1);

  const [pakagePrice, setPakagePrice] = useState(0);

  const [disabled, setDisabled] = useState<boolean>(false);

  const [loadingButtonPurchase, setLoadingButtonPurchase] = useState(false);

  const handleChangePointPakage = data => {
    setPointPakageSelect(data);
    setPakagePrice(data.price);
    setPakageQuantity(prev => (prev ? prev : 1));
  };

  const handleChangeQuantity = e => {
    const { value } = e.target;

    if (value !== '' && (value === '0' || !positiveRegex.test(value))) {
      setDisabled(true);
    } else {
      if (!positiveExcludeZeroRegex.test(value)) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }

    if (value < 1000) {
      setPakageQuantity(value);
    }
  };

  async function purchasePointPackage() {
    try {
      setLoadingButtonPurchase(true);
      const res: any = await purchasePoint({
        id: pointPakageSelect.id,
        quantity: +pakageQuantity,
      });

      if (res?.statusCode === 201) {
        dispatch(
          actionsToast.openToast({
            isCloseOkCustom: true,
            imageElement: ClockIcon,
            titleCustom: '確認',
            message: `<p style="text-align: left">お支払いページに移動します。よろしいですか？</p>`,
            type: 'custom',
            buttonElement: `<a class="custom-btn" href=${res?.data} target="_blank" rel="noopener noreferrer">はい</a>`,
          }),
        );
        setLoadingButtonPurchase(false);
      } else {
        toast.error('Purchase point fail');
        setLoadingButtonPurchase(false);
      }
    } catch (error: any) {
      const code = error?.response?.status;
      if (code === 400) {
        toast.error('You must purchase more than 50 cents ($0.5 US)');
      } else {
        toast.error('Purchase point fail');
      }

      setLoadingButtonPurchase(false);
    } finally {
      setShowPointPopup(false);
      setLoadingButtonPurchase(false);
    }
  }

  useEffect(() => {
    if (pointPakageData?.length > 0) {
      setPointPakageSelect(pointPakageData[0]);
      setPakagePrice(pointPakageData[0]?.price);
    }
  }, [pointPakageData]);

  return {
    pointPakageSelect,
    pakageQuantity,
    pakagePrice,
    disabled,
    loadingButtonPurchase,
    handleChangePointPakage,
    handleChangeQuantity,
    purchasePointPackage,
    isMobile,
  };
};
